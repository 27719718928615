import { ClientPerspective } from 'next-sanity';
import { dataset, projectId, apiVersion } from './client.env';
const isProduction =
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' || false;
const config = {
  projectId: projectId,
  dataset: dataset,
  apiVersion: apiVersion,
  useCdn: isProduction,
  perspective: 'published' as ClientPerspective,
  stega: {
    studioUrl: '/admin',
    filter: (props: any) => {
      const { sourcePath } = props;
      const excludedFields = [
        'displayOptions',
        'color',
        'heading',
        'subHeading',
        'button',
        'backgroundColor',
        'muxVideoId',
        'navbarBgColor',
        'navbarOverlay',
      ];
      // if any of the excluded fields are in the source path, return false
      if (excludedFields.some((field) => sourcePath.includes(field))) {
        return false;
      }

      // // The default behavior is packaged into `filterDefault`, allowing you to enable encoding fields that are skipped by default
      return props.filterDefault(props);
    },
  },
};

export default config;
