'use client';
import { mixpanelPopupView } from '@/lib/mixpanel/services/client';
import blocksToText from '@/sanity/utils/shopify/blocksToText';
import { userClickSSULinkAtom } from '@/store/ssuStore';
import {
  toastsShownPerSessionAtom,
  toastAddToCartAtom,
} from '@/store/toastsStore';
import { useAtom, useAtomValue } from 'jotai';
import React, { useEffect, useRef } from 'react';
import { toast as toastify, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastContent from './ToastContent';
import { Toast } from '@/types/types';
import { usePathname, useSearchParams } from 'next/navigation';

/**
 * Toast Component from Sanity notifications
 * @returns JSX Element for the toast component
 */
export default function ToastTrigger({ toast }: { toast: Toast }) {
  const userClickSSULink = useAtomValue(userClickSSULinkAtom);
  const userAddedToCart = useAtomValue(toastAddToCartAtom);
  const [toastsShownPerSession, setToastsShownPerSession] = useAtom(
    toastsShownPerSessionAtom
  );

  const pathname = usePathname();
  const searchParams = useSearchParams();
  const showToast = useRef(false);
  const timeoutId = useRef<NodeJS.Timeout | null>(null);

  // Cleanup function to clear timeouts
  const clearTimers = () => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
      timeoutId.current = null;
    }
  };

  const delay = toast?.displayOptions?.delay
    ? toast.displayOptions.delay * 1000
    : 5000;

  const duration = toast?.displayOptions?.duration
    ? toast?.displayOptions?.duration * 1000
    : 10000;

  // Function to check if the pathname should be excluded
  const isPathExcluded = (pathname: string, excludePatterns: string[]) => {
    return excludePatterns.some((pattern) => {
      if (pattern.endsWith('*')) {
        const basePath = pattern.slice(0, -1);
        return pathname.startsWith(basePath);
      }
      return pathname === pattern;
    });
  };

  // Function to check if the pathname and query parameters match the include patterns
  const isPathIncluded = (
    pathname: string,
    searchParams: URLSearchParams,
    includePatterns: {
      path: string;
      query?: string[];
    }[]
  ) => {
    const searchParamsString = searchParams.toString();
    return includePatterns.some(({ path, query }) => {
      if (pathname !== path) return false;
      if (query) {
        return query.every((q) => searchParamsString.includes(q));
      }
      return true;
    });
  };

  // Function to update the toastsShownPerSession atom added to session list
  const updateToastsShownPerSession = () => {
    setToastsShownPerSession((prev) => {
      const safePrev = Array.isArray(prev) ? prev : [];
      if (!safePrev.includes(toast._key)) {
        return [toast._key, ...safePrev];
      }
      return safePrev;
    });
  };

  useEffect(() => {
    try {
      const isUserClickSSULink =
        userClickSSULink && toast?.button?.link?.linkType === 'ssu';
      const isToastShown =
        toast?._key && toastsShownPerSession?.includes(toast?._key);
      const isUserAddedToCartNull =
        userAddedToCart === null &&
        toast?.button?.link?.url === 'purchase-journey';

      if (isUserClickSSULink || isToastShown || isUserAddedToCartNull) {
        clearTimers();
        return;
      }

      if (!showToast.current) {
        showToast.current = true;
        timeoutId.current = setTimeout(() => {
          const includePaths = toast.displayOptions?.include?.routes || [];
          const excludePaths = toast.displayOptions?.exclude?.routes || [];

          if (includePaths.length > 0) {
            if (
              !isPathIncluded(
                window.location.pathname,
                searchParams,
                includePaths
              )
            ) {
              return;
            }
          } else if (excludePaths.length > 0) {
            if (isPathExcluded(window.location.pathname, excludePaths)) {
              return;
            }
          }

          toastify[toast?.displayOptions?.type || 'info'](
            ToastContent({ toast }),
            {
              className: `!w-full !bg-gradient-to-r ${toast?.displayOptions?.type === 'error' ? 'from-red-600 to-red-700' : '!bg-gradient-teal'} !text-white !shadow !shadow-lg`,
              autoClose: duration,
              delay: 0,
              onClick: updateToastsShownPerSession,
              onClose: updateToastsShownPerSession,
              onOpen: () => {
                // @ts-ignore
                mixpanelPopupView(blocksToText(toast?.message));
              },
            }
          );

          clearTimers();
        }, delay);
      }
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userClickSSULink,
    toastsShownPerSession,
    pathname,
    searchParams,
    userAddedToCart,
    toast,
  ]);

  const isUserClickSSULink =
    userClickSSULink && toast?.button?.link?.linkType === 'ssu';
  const isToastShown =
    toast?._key && toastsShownPerSession?.includes(toast?._key);
  const isUserAddedToCartNull =
    userAddedToCart === null && toast?.button?.link?.url === 'purchase-journey';

  if (isUserClickSSULink || isToastShown || isUserAddedToCartNull) {
    return null;
  }

  return (
    <ToastContainer
      position={toast?.displayOptions?.position}
      draggable
      theme='light'
      icon={false}
      className={`!z-400 md:!w-[400px]`}
      progressClassName={`!bg-charcoal`}
    />
  );
}
