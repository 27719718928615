export const useCdn = false;

/**
 * As this file is reused in several other files, try to keep it lean and small.
 * Importing other npm packages here could lead to needlessly increasing the client bundle size, or end up in a server-only function that don't need it.
 */

export const dataset = assertValue(
  process.env.NEXT_PUBLIC_SANITY_DATASET,
  'Missing environment variable: NEXT_PUBLIC_SANITY_DATASET'
);

export const projectId = assertValue(
  process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
  'Missing environment variable: NEXT_PUBLIC_SANITY_PROJECT_ID'
);

export const readToken = process.env.SANITY_API_WRITE_TOKEN || '';

export const title =
  process.env.NEXT_PUBLIC_SANITY_PROJECT_TITLE || 'iK Website';

// see https://www.sanity.io/docs/api-versioning for how versioning works
export const apiVersion =
  process.env.NEXT_PUBLIC_SANITY_API_VERSION || 'v2024-01-11';

// This is the document id used for the preview secret that's stored in your dataset.
// The secret protects against unauthorized access to your draft content and have a lifetime of 60 minutes, to protect against bruteforcing.
export const previewSecretId: `${string}.${string}` = 'preview.secret';

//workspace config

export const workspace1Dataset =
  process.env.NEXT_PUBLIC_WORKSPACE_1_SANITY_DATASET || '';
export const workspace1Title =
  process.env.NEXT_PUBLIC_WORKSPACE_1_SANITY_TITLE || '';
export const workspace1Icon =
  process.env.NEXT_PUBLIC_WORKSPACE_1_SANITY_ICON || '';

export const workspace2Dataset =
  process.env.NEXT_PUBLIC_WORKSPACE_2_SANITY_DATASET || '';
export const workspace2Title =
  process.env.NEXT_PUBLIC_WORKSPACE_2_SANITY_TITLE || '';
export const workspace2Icon =
  process.env.NEXT_PUBLIC_WORKSPACE_2_SANITY_ICON || '';
export const workspace2Path =
  process.env.NEXT_PUBLIC_WORKSPACE_2_SANITY_PATH || '';

function assertValue<T>(v: T | undefined, errorMessage: string): T {
  if (v === undefined) {
    throw new Error(errorMessage);
  }

  return v;
}
