'use client';

import { useLiveMode } from '@sanity/react-loader';
import { VisualEditing } from 'next-sanity';

import { client } from '@/sanity/lib/client';
import { DisableDraftMode } from './DisableDraftMode';

// Always enable stega in Live Mode
const stegaClient = client.withConfig({ stega: true });

export default function LiveVisualEditing() {
  useLiveMode({ client: stegaClient });

  return (
    <>
      <DisableDraftMode />
      <VisualEditing />
    </>
  );
}
