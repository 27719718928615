'use client';

import { useTransition } from 'react';

export function DisableDraftMode() {
  const [pending, startTransition] = useTransition();

  if (window !== window.parent) {
    console.log('Not in iframe, not disabling draft mode');
    return null;
  }

  const disable = () =>
    startTransition(async () => {
      location.href = '/api/disable-draft';
    });

  return (
    <div className='fixed bottom-4 left-4 z-50'>
      <button
        type='button'
        onClick={disable}
        className='rounded-lg bg-red-600 px-4 py-2 font-semibold text-white shadow-md transition-all hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50'
      >
        {pending ? 'Disabling...' : 'Disable Draft Mode'}
      </button>
    </div>
  );
}
